import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import CommentApi from '../services/api/comment'

type LoadingBtnRefrechProps = {
    idPage: string
    access_token: string
    setDisabledRefreshPage: Function
}

export default function LoadingBtnRefrech({ idPage, access_token, setDisabledRefreshPage }: LoadingBtnRefrechProps) {
    const [btnEnabled, setBtnEnabled] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleRefrechPage = async (event: React.MouseEvent) => {
        event.stopPropagation();
        setLoading(true)
        setDisabledRefreshPage(true)
        setBtnEnabled(true)
        await CommentApi.refrechCommentsPerOfPage(access_token, idPage)
            .then(() =>{
                setDisabledRefreshPage(false)
                setLoading(false)
                setBtnEnabled(false)
            }
        )
        .catch(error =>console.log(error))
    }
    return (
        <LoadingButton
            size="large"
            color={"primary"}
            variant="contained"
            loadingPosition="end"
            onClick={(event) => handleRefrechPage(event)}
            loading={loading}
            disabled={btnEnabled}
        >
            Refresh
        </LoadingButton>
    )
}